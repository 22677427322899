import { ElementRef, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { AppService } from 'services/application/application.service';
var ClasslinkSSOLoginComponent = /** @class */ (function () {
    function ClasslinkSSOLoginComponent(appSvc, route, router) {
        this.appSvc = appSvc;
        this.route = route;
        this.router = router;
        this._subrelease = new Subject();
    }
    ClasslinkSSOLoginComponent.prototype.ngOnDestroy = function () {
        this._subrelease.next();
        this._subrelease.complete();
    };
    ClasslinkSSOLoginComponent.prototype.ngOnInit = function () {
        var self = this;
        self.ActionURL = null;
        self.RelayState = null;
        self.SAMLResponse = null;
        // Listen for the parameters
        self.route.queryParamMap
            .pipe(takeUntil(self._subrelease), map(function (params) {
            return {
                Token: params.get('code')
            };
        })).subscribe(function (ssoinfo) {
            // If we're landing here, and there's no token provided, this must be the initial landing on the page from Classlink.
            // We must redirect back to them via the client_id value so ClassLink can prompt for authentication, and then it'll return back here
            // with the token, from which we can continue authentication.
            if (!ssoinfo || !ssoinfo.Token) {
                // Initial Classlink redirect
                if (window.location.href.indexOf('localhost') >= 0) {
                    window.location.href = 'https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=http%3A%2F%2Flocalhost%3A4270%2Fclasslink&client_id=c1540573400626d41dbdae714e7192a9f40b845b38dd23&response_type=code';
                }
                else {
                    window.location.href = 'https://launchpad.classlink.com/oauth2/v2/auth?scope=full&redirect_uri=https%3A%2F%2Fgapps.mypolkschools.net%2Fclasslink&client_id=c15405732270784273243b9ce359b3032b5accd3140078&response_type=code';
                }
                return;
            }
            // This is a redirect from Classlink back to us. In this case, extract the token and process it for logging in.
            var decodedToken = decodeURIComponent(ssoinfo.Token);
            // Grab the screen and window resolutions
            var scr = window.screen.width + 'x' + window.screen.height;
            var win = window.innerWidth + 'x' + window.innerHeight;
            // Pass to the Classlink SSO Login routine
            self.appSvc.ClasslinkSSOLogin(decodedToken, scr, win)
                .subscribe(function (result) {
                // Use the returned user info to complete the "login" process.
                self.ActionURL = result.ActionURL;
                self.RelayState = result.RelayState;
                self.SAMLResponse = result.SAMLResponse;
                // Trigger the Google form
                window.setTimeout(function () {
                    var gform = self._GoogleForm.nativeElement;
                    gform.submit();
                }, 0);
                return;
            }, function (error) {
                // Transition to the regular login route
                self.router.navigate(['/']);
            });
        });
    };
    return ClasslinkSSOLoginComponent;
}());
export { ClasslinkSSOLoginComponent };
