import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { Subscription, TeardownLogic, timer } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { AppService } from "services/application/application.service";
import { ErrorService } from "services/error/error.service";
import { toUnicode } from "punycode";

@Component({
  selector: "app-login",
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild("googleform") _GoogleForm: ElementRef;
  @ViewChild("Password") _PasswordField: ElementRef;
  private _subrelease: Subscription = new Subscription();
  set subrelease(sub: TeardownLogic) {
    this._subrelease.add(sub);
  }

  ActionURL: string;
  ErrorMessage: string;
  GoogleRelayState: string;
  GoogleSAMLRequest: string;
  LoginForm: FormGroup;
  RelayState: string;
  SAMLRequest: string;
  SAMLResponse: string;
  ShowImpersonationBox: boolean;

  constructor(
    private appSvc: AppService,
    private errorSvc: ErrorService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnDestroy() {
    if (this._subrelease) {
      this._subrelease.unsubscribe();
    }
  }

  ngOnInit() {
    const self = this;

    self.ActionURL = null;
    self.ErrorMessage = null;
    self.GoogleRelayState = null;
    self.GoogleSAMLRequest = null;
    self.RelayState = null;
    self.SAMLRequest = null;
    self.SAMLResponse = null;
    self.ShowImpersonationBox = false;

    self.LoginForm = self.fb.group({
      Username: [null, Validators.required],
      Password: [null, Validators.required],
      AccessCode: [null],
    });

    // Listen for the parameters (from Google, if present)
    self._subrelease.add(
      self.route.queryParamMap
        .pipe(
          map((params) => {
            return {
              SAMLRequest: params.get("SAMLRequest"),
              RelayState: params.get("RelayState"),
            };
          })
        )
        .subscribe((googleInfo) => {
          if (googleInfo != null) {
            self.SAMLRequest = self.GoogleSAMLRequest = !googleInfo.SAMLRequest
              ? null
              : decodeURIComponent(googleInfo.SAMLRequest);
            self.RelayState = self.GoogleRelayState = !googleInfo.RelayState
              ? null
              : googleInfo.RelayState;
          }
        })
    );

    // Set up a subscription on Test Connection, to check for the system being available while the user is sitting on the landing page.
    this.subrelease = timer(0, 60000).subscribe((_) => {
      // Call out to test the connection.
      this.appSvc.TestConnection().subscribe(
        (result) => {
          // Update the status.
          this.appSvc.SetSystemOnlineStatus(result);
        },
        (err) => {
          this.appSvc.SetSystemOnlineStatus({
            Status: false,
            SystemMessage: "",
          });
        }
      );
    });
  }

  // #region Show Impersonation Method

  ShowImpersonation(event: any) {
    if (!event) {
      return;
    }

    if (event.ctrlKey || event.metaKey || event.tapCount === 2) {
      this.ShowImpersonationBox = !this.ShowImpersonationBox;
    }
  }

  // #endregion

  Submit() {
    const self = this;

    if (!self.LoginForm) {
      return;
    }

    const form = self.LoginForm.getRawValue();

    if (!form.Username || !form.Password) {
      return;
    }

    // Let's try to help the users out a bit. First, let's trim whitespace of the front and end of the username field.
    let username = (form.Username as string).trim().toLowerCase();
    let toUpdate = false;

    // Now, if there's a domain as part of this, we do some more helpful tricks.
    if (username.indexOf("@") >= 0) {
      // Split off the domain portion and the root portion.
      const root = username.substring(0, username.indexOf("@"));
      const domain = username.substring(username.indexOf("@") + 1);

      // If any of the following common failed domains are found, we can simply replace with the valid one to help out the user.
      // 54 so far - JGK 4/7/20
      const commonBadDomains = [
        "atmypolkschools.net",
        "mpolkschools.net",
        "mupolkschools.net",
        "my polkschools.net",
        "mylolkschools.net",
        "myoolkschools.net",
        "mypilkschools.net",
        "myplkschools.net",
        "myplokschool.net",
        "myplokschoos.net",
        "myplokschools.net",
        "mypoklschools.net",
        "mypokschools.net",
        "mypolk.net",
        "mypolkachools.net",
        "mypolkchools.net",
        "mypolkcountyschools.net",
        "mypolkscgools.net",
        "mypolkschhapools.net",
        "mypolkschhols.net",
        "mypolkschols.net",
        "mypolkscholls.net",
        "mypolk school.net",
        "mypolkschool",
        "mypolkschool.com",
        "mypolkschool.net",
        "mypolkschoold.net",
        "mypolkschoolk.net",
        "mypolkschools",
        "mypolkschools. net",
        "mypolkschools,net",
        "mypolkschools.com",
        "mypolkschools.edu",
        "mypolkschools.nrt",
        "mypolkschools.org",
        "mypolkschools.ney",
        "mypolkschoolsfl.net",
        "mypolkschoolsnet",
        "mypolkschoools.net",
        "mypolkschoos.net",
        "mypolkscools.net",
        "mypolkshcools.com",
        "mypolkshcools.net",
        "mypolkshools",
        "mypolkshools.net",
        "mypolksschools.net",
        "mypollkschools,net",
        "mypollkschools.net",
        "mypollschools.net",
        "mypolschools.net",
        "mypolkstudents.net",
        "mypollkschools.net",
        "myschool.net",
        "myschools.net",
        "my.polkschools.net",
        "polkschool.net",
        "polkschools.net",
        "polkscools.net",
        "ypolkschools.net",
      ];

      if (commonBadDomains.includes(domain)) {
        username = `${root.trim()}@mypolkschools.net`;

        toUpdate = true;
      } else if (root.trim() !== root) {
        username = `${root.trim()}@${domain}`;

        toUpdate = true;
      }
    }

    // Update the form if we need to.
    if (toUpdate) {
      self.LoginForm.get("Username").setValue(username);
    }

    // Validate what was provided.
    self.appSvc
      .ValidateCredentials(
        username,
        form.Password,
        form.AccessCode,
        self.SAMLRequest,
        self.RelayState,
        window.location.href
      )
      .subscribe(
        (result) => {
          // A success result. Clear the error message display (if any)
          self.ErrorMessage = null;

          // Set the properties from the response.
          self.ActionURL = result.ActionURL;
          self.RelayState = self.GoogleRelayState = result.RelayState;
          self.SAMLResponse = result.SAMLResponse;

          // Trigger the Google form
          window.setTimeout(function () {
            const gform = <HTMLFormElement>self._GoogleForm.nativeElement;
            gform.submit();
          }, 0);
        },
        (error) => {
          const msg = self.errorSvc.RetrieveServiceErrorMessage(error);

          // Display this error in the component field.
          self.ErrorMessage = msg;

          // Clear the login form's password field, set the focus back to that field.
          self.LoginForm.get("Password").setValue(null);
          self.LoginForm.updateValueAndValidity();

          self._PasswordField.nativeElement.focus();
        }
      );
  }
}
