<div id="top">
  <div id="tContent"></div>
</div>
<div id="header">
  <div id="hContent">
    <h1 id="dummy">myPolkApps - Main Login</h1>
    <img id="mypolkappslogo" src="assets/mpalogo2.png" />
    <!-- <div id="poweredby" class="hide-lt-650"></div> -->
  </div>
</div>
<div id="bodywrap">
  <div
    id="loginContent"
    class="flex-row flex-axis-space-around flex-crossaxis-start padding-top"
  >
    <div id="welcome" class="hide-lt-780">
      <h2>Let's get started!</h2>
      <p class="margin-bottom">
        Work online or offline, anytime, and on any device with
        <strong>myPolkApps</strong>, powered by Google. Use these tools to
        encourage collaboration, creativity, and critical thinking, and work
        one-on-one or with the whole class.
      </p>
    </div>
    <div id="login">
      <h2 appMobileTap (doubleTap)="ShowImpersonation($event)">Log In</h2>
      <div class="ValidationMessage" *ngIf="ErrorMessage">
        {{ ErrorMessage }}
      </div>
      <div>
        Note: Student usernames should be in either of these forms:
        <em>username</em> -or- <em>username@mypolkschools.net</em>
      </div>
      <form
        id="form1"
        class="loginform"
        [formGroup]="LoginForm"
        novalidate
        (ngSubmit)="Submit()"
      >
        <p><label>Username:</label></p>
        <input
          type="text"
          id="Username"
          name="Username"
          class="wide"
          autofocus
          formControlName="Username"
        />
        <p><label>Password:</label></p>
        <input
          type="password"
          #Password
          id="Password"
          name="Password"
          class="wide"
          formControlName="Password"
        />
        <p *ngIf="ShowImpersonationBox"><label>Access Code:</label></p>
        <input
          *ngIf="ShowImpersonationBox"
          type="text"
          id="AccessCode"
          name="AccessCode"
          class="wide"
          formControlName="AccessCode"
        />
        <p>
          <input
            type="submit"
            class="button"
            value="Sign in"
            [disabled]="!LoginForm.valid"
          />
        </p>
        <a href="https://password.mypolkschools.net/?from=Google" tabindex="999"
          >Forgot My Password</a
        >
        <input
          type="hidden"
          id="SAMLRequest"
          name="SAMLRequest"
          [value]="SAMLRequest"
        />
        <input
          type="hidden"
          id="RelayState"
          name="RelayState"
          [value]="RelayState"
        />
        <input
          type="hidden"
          id="GoogleSAMLRequest"
          name="GoogleSAMLRequest"
          [value]="GoogleSAMLRequest"
        />
        <input
          type="hidden"
          id="GoogleRelayState"
          name="GoogleRelayState"
          [value]="GoogleRelayState"
        />
      </form>
    </div>
    <!-- <div class="clear"></div> -->
    <div class="hidden">
      <form id="googleform" method="POST" [attr.action]="ActionURL" #googleform>
        <textarea
          id="SAMLResponse"
          name="SAMLResponse"
          cols="130"
          rows="15"
          [value]="SAMLResponse"
        ></textarea
        ><br />
        <textarea
          id="GFRelayState"
          name="RelayState"
          cols="130"
          rows="15"
          [value]="RelayState"
        ></textarea>
      </form>
    </div>
  </div>
</div>
<div id="footer">
  <div id="fContent">
    <div id="about">
      <h3 class="spaced">Please Note:</h3>
      <p>
        Students will use their Polk ID credentials (i.e., johnt4).<br />
        Staff will use their Polk-FL credentials (i.e., jane.test).
      </p>
    </div>
  </div>
</div>
