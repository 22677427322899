import { EventEmitter } from '@angular/core';
var MobileTapDirective = /** @class */ (function () {
    function MobileTapDirective() {
        this.doubleTap = new EventEmitter();
    }
    MobileTapDirective.prototype.onTap = function (e) {
        if (e.preventDefault) {
            e.preventDefault();
        }
        if (e.tapCount === 2) {
            this.doubleTap.emit(e);
        }
    };
    return MobileTapDirective;
}());
export { MobileTapDirective };
