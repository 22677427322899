<!-- <div
  fxLayout="row"
  fxLayoutAlign="center center"
  fxLayoutAlign.lt-md="center center"
  [ngClass.gt-sm]="'blocks-container'"
  class="menu-container-parent"
>
  <div
    fxFlex="50"
    fxLayout="row"
    fxLayoutAlign="start start"
    fxLayoutGap="20px"
    fxFlex.lt-md="95"
    class="menu-container"
  >
    <div
      class="main-menu"
      fxLayout="column"
      fxLayoutAlign="start center"
      fxLayoutGap="30px"
    >
      <div class="incoming-block mat-elevation-z10-light-shadow">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
          <mat-icon class="large">wifi_off</mat-icon>
          <span class="block-label mat-headline"
            >myPolkApps Temporarily Unavailable</span
          >
        </div>
        <p class="margin-top">
          <span
            >We're sorry, but the myPolkApps sign-in application is currently
            unavailable. We apologize for the inconvenience. This page will
            continue checking to see if the system has become available again,
            and will transfer you to the login page when the system is back
            online. Thank you for your patience during this outage.</span
          >
        </p>
      </div>
    </div>
  </div>
</div> -->
<div id="top">
  <div id="tContent"></div>
</div>
<div id="header">
  <div id="hContent">
    <h1 id="dummy">myPolkApps - Main Login</h1>
    <img id="mypolkappslogo" src="assets/mpalogo2.png" />
    <!-- <div id="poweredby" class="hide-lt-650"></div> -->
  </div>
</div>
<div id="bodywrap">
  <div
    id="loginContent"
    class="flex-row flex-center padding-top flex-crossaxis-center"
  >
    <div id="offline">
      <h2>System is currently unavailable!</h2>
      <p class="margin-bottom">
        We're sorry, but the myPolkApps sign-in application is currently
        unavailable. We apologize for the inconvenience. This page will continue
        checking to see if the system has become available again, and will
        transfer you to the login page when the system is back online. Thank you
        for your patience during this outage.
      </p>
      <div class="margin-top">
        <div class="margin-bottom-thin">
          Time until next check: {{ TimerCount }}
        </div>
        <div class="progress-bar-container">
          <div
            class="progress-bar"
            [style.width]="(TimerCount * 100.0) / TimerMax + '%'"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="footer">
  <div id="fContent">
    <div id="about">
      <h3 class="spaced">Please Note:</h3>
      <p>
        This page will continue to check the myPolkApps system and will redirect
        you to the login page once the system has returned to online status.
      </p>
    </div>
  </div>
</div>
