import { Component, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";

import { Subscription, TeardownLogic, timer } from "rxjs";

import { AppService } from "services/application/application.service";

@Component({
  selector: "app-offline",
  styleUrls: ["offline.component.scss"],
  templateUrl: "offline.component.html",
})
export class OfflineComponent implements OnDestroy {
  private _subrelease = new Subscription();
  set subrelease(sub: TeardownLogic) {
    this._subrelease.add(sub);
  }

  readonly TimerMax = 60;
  TimerCount = this.TimerMax;

  constructor(private appSvc: AppService, private titleSvc: Title) {
    this.titleSvc.setTitle(
      "myPolkApps - Polk County Public Schools | System Currently Unavailable"
    );

    // Set up a subscription on Test Connection, to check for the system being available while waiting for it to resume.
    // If the user isn't logged in and we detect a new version is available, silently go ahead and reload.
    this.subrelease = timer(0, 1000).subscribe((_) => {
      this.TimerCount--;

      if (this.TimerCount === 0) {
        // Call out to test the connection.
        this.appSvc.TestConnection().subscribe(
          (result) => {
            // Update the status.
            this.appSvc.SetSystemOnlineStatus(result);

            // And reset the tick count.
            this.TimerCount = this.TimerMax;
          },
          (err) => {
            this.appSvc.SetSystemOnlineStatus({
              Status: false,
              SystemMessage: "",
            });
          }
        );
      }
    });
  }

  ngOnDestroy(): void {
    this._subrelease.unsubscribe();
  }
}
