import { Routes } from "@angular/router";
import { ClasslinkSSOLoginComponent } from "app/classlink/classlink.component";
import { LoginComponent } from "app/login/login.component";
import { OfflineComponent } from "app/offline/offline.component";
var routes = [
    { path: 'classlink', component: ClasslinkSSOLoginComponent },
    { path: "offline", component: OfflineComponent },
    { path: '', component: LoginComponent, pathMatch: 'full' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
