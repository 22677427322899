import { OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Subscription, timer } from "rxjs";
import { AppService } from "services/application/application.service";
var OfflineComponent = /** @class */ (function () {
    function OfflineComponent(appSvc, titleSvc) {
        var _this = this;
        this.appSvc = appSvc;
        this.titleSvc = titleSvc;
        this._subrelease = new Subscription();
        this.TimerMax = 60;
        this.TimerCount = this.TimerMax;
        this.titleSvc.setTitle("myPolkApps - Polk County Public Schools | System Currently Unavailable");
        // Set up a subscription on Test Connection, to check for the system being available while waiting for it to resume.
        // If the user isn't logged in and we detect a new version is available, silently go ahead and reload.
        this.subrelease = timer(0, 1000).subscribe(function (_) {
            _this.TimerCount--;
            if (_this.TimerCount === 0) {
                // Call out to test the connection.
                _this.appSvc.TestConnection().subscribe(function (result) {
                    // Update the status.
                    _this.appSvc.SetSystemOnlineStatus(result);
                    // And reset the tick count.
                    _this.TimerCount = _this.TimerMax;
                }, function (err) {
                    _this.appSvc.SetSystemOnlineStatus({
                        Status: false,
                        SystemMessage: "",
                    });
                });
            }
        });
    }
    Object.defineProperty(OfflineComponent.prototype, "subrelease", {
        set: function (sub) {
            this._subrelease.add(sub);
        },
        enumerable: true,
        configurable: true
    });
    OfflineComponent.prototype.ngOnDestroy = function () {
        this._subrelease.unsubscribe();
    };
    return OfflineComponent;
}());
export { OfflineComponent };
