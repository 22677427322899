import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ClasslinkSSOLoginComponent } from "app/classlink/classlink.component";
import { LoginComponent } from "app/login/login.component";
import { OfflineComponent } from "app/offline/offline.component";

const routes: Routes = [
  { path: 'classlink', component: ClasslinkSSOLoginComponent },
  { path: "offline", component: OfflineComponent },
  { path: '', component: LoginComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
