import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";

import { NgxWebstorageModule } from "ngx-webstorage";

import { AppComponent } from "./app.component";
import { ClasslinkSSOLoginComponent } from "app/classlink/classlink.component";
import { LoginComponent } from "app/login/login.component";
import { MobileTapDirective } from "app/mobiletap.directive";
import { OfflineComponent } from "app/offline/offline.component";

import { AppRoutingModule } from "src/routes/app-routing-module";

@NgModule({
  declarations: [
    AppComponent,
    ClasslinkSSOLoginComponent,
    LoginComponent,
    MobileTapDirective,
    OfflineComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgxWebstorageModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
