var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject, Subscription } from "rxjs";
import { SessionStorage } from "ngx-webstorage";
import { ConfigService } from "services/config/config.service";
import * as i0 from "@angular/core";
import * as i1 from "../config/config.service";
import * as i2 from "@angular/common/http";
var AppService = /** @class */ (function () {
    function AppService(configSvc, http) {
        this.configSvc = configSvc;
        this.http = http;
        this._subrelease = new Subscription();
        this.systemOnline = false;
        this.SystemOnlineSubject = new Subject();
        this.SystemOnlineSubject.next({
            SystemMessage: "",
            Status: this.systemOnline,
            GoogleSSOVersion: "",
        });
    }
    Object.defineProperty(AppService.prototype, "subrelease", {
        set: function (sub) {
            this._subrelease.add(sub);
        },
        enumerable: true,
        configurable: true
    });
    AppService.prototype.ngOnDestroy = function () {
        this._subrelease.unsubscribe();
    };
    // #region Classlink SSO Login Service Call
    AppService.prototype.ClasslinkSSOLogin = function (token, screen, winres) {
        return this.http.post(this.configSvc.hostAddress + "Api/Services/App/ClasslinkSSOLogin", {
            Token: token,
            Screen: screen,
            Window: winres,
        });
    };
    // #endregion
    // #region Set System Online Status
    AppService.prototype.SetSystemOnlineStatus = function (status) {
        this.systemOnline = (status && status.Status) || false;
        this.SystemOnlineSubject.next(status);
    };
    // #endregion
    // #region Test Connection
    AppService.prototype.TestConnection = function () {
        return this.http.get(this.configSvc.hostAddress + "Api/Services/App/TestConnection");
    };
    // #endregion
    // #region Validate Credentials
    AppService.prototype.ValidateCredentials = function (username, password, accesscode, SAMLRequest, RelayState, fullURL) {
        return this.http.post(this.configSvc.hostAddress + "Api/Services/App/ValidateCredentials", {
            Username: username || "",
            Password: password || "",
            AccessCode: accesscode || "",
            SAMLRequest: SAMLRequest || "",
            RelayState: RelayState || "",
            FullURL: fullURL || ''
        });
    };
    AppService.ngInjectableDef = i0.defineInjectable({ factory: function AppService_Factory() { return new AppService(i0.inject(i1.ConfigService), i0.inject(i2.HttpClient)); }, token: AppService, providedIn: "root" });
    __decorate([
        SessionStorage(),
        __metadata("design:type", Object)
    ], AppService.prototype, "systemOnline", void 0);
    return AppService;
}());
export { AppService };
// #endregion
// #region Validation Result Class
var ValidationResult = /** @class */ (function () {
    function ValidationResult() {
    }
    return ValidationResult;
}());
export { ValidationResult };
