import { ElementRef, OnInit, OnDestroy, } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { AppService } from "services/application/application.service";
import { ErrorService } from "services/error/error.service";
var AppComponent = /** @class */ (function () {
    function AppComponent(appSvc, errorSvc, fb, router, titleSvc) {
        var _this = this;
        this.appSvc = appSvc;
        this.errorSvc = errorSvc;
        this.fb = fb;
        this.router = router;
        this.titleSvc = titleSvc;
        this._subrelease = new Subscription();
        var self = this;
        this.ActionURL = null;
        this.ErrorMessage = null;
        this.GoogleRelayState = null;
        this.GoogleSAMLRequest = null;
        this.RelayState = null;
        this.SAMLRequest = null;
        this.SAMLResponse = null;
        this.titleSvc.setTitle("myPolkApps - Polk County Public Schools");
        this.LoginForm = this.fb.group({
            Username: [null, Validators.required],
            Password: [null, Validators.required],
        });
        // Subscribe to system online status updates. If the system goes offline, this may cause us to reroute to the offline route.
        self.subrelease = self.appSvc.SystemOnlineSubject.pipe(distinctUntilChanged(function (p, c) { return p.Status === c.Status; })).subscribe(function (status) {
            if (!status || !status.Status) {
                // The system is showing as offline. Route to the offline route.
                self.router.navigate(["/offline"], { queryParamsHandling: "preserve" });
            }
            else if (status && status.Status) {
                // The system is showing as online. Route to the home route if they are coming in without a route, and do nothing
                // if they are coming in with classlink pass-through.
                if (self.router.url === "/") {
                    self.router.navigate([""], { queryParamsHandling: "preserve" });
                }
            }
        });
        // Conduct an initial test of the connection, to set the proper status.
        this.appSvc.TestConnection().subscribe(function (result) {
            _this.appSvc.SetSystemOnlineStatus(result);
        }, function (err) {
            // A failure would indicate the system is offline.
            _this.appSvc.SetSystemOnlineStatus({
                Status: false,
                SystemMessage: "",
            });
        });
        // if (window.location.href.indexOf("SAMLRequest") < 0) {
        //   window.location.href =
        //     "http://localhost:4270/?SAMLRequest=fVJNT9wwEL1X4j9YvudrKyFqbYK2IMRKtI3YwKE3x5ls3LU9rsfZLf%2B%2B2SwIOMD1%2Bc37GM%2Fy8p81bA%2BBNLqSF2nOGTiFnXbbkj80N8kFv6zOvixJWuPFaoyDu4e%2FI1Bk06QjMT%2BUfAxOoCRNwkkLJKISm9WPO7FIc%2BEDRlRoOFtfl7x10Leu960ddI%2B73kqU0nlvhu3gB9PJP0a33u04e3yJtTjGWhONsHYUpYsTlBffkvwiKc6b4qvIF2Jx%2Fpuz%2Btnpu3anBp%2FFak8kErdNUyf1r00zC%2Bx1B%2BHnxC75FnFrIFVoj%2Fa1JNL7Ce6lIeBsRQQhTgGv0NFoIWwg7LWCh%2Fu7kg8xehJZdjgc0leZTGb2yaPZkRoQDaUOYiYV8WpesJg7hjeb%2FbyBfEnAq1ePZfZGqnr%2BuGOf9XWNRqsntjIGD1cBZJzKxDBOXW4wWBk%2FdivSYkZ0l%2FQzVYyOPCjda%2Bg4y6qT6%2FsLme7mPw%3D%3D&RelayState=https%3A%2F%2Faccounts.google.com%2FCheckCookie%3Fplt%3DAKgnsbvPhX4LxWKysoUSHr_v4YYWi-Sba_sgzeLwRUFH_1B7EvRsDFleBC-nkHxzw_9ST-QT0KJE6v7hzU8m4R1K3_iQQ9GReHr9IaIqf-8nAvORIjyg5nuqGkFjH9nfglWi9q0cCtXM%26continue%3Dhttps%253A%252F%252Faccounts.google.com%252Fprogrammatic_auth%253Fpart%253DAJi8hAN7GMo2a3w8f4-UjanI-5h4o5clEiSDjLjQzML6ampXVGCftUSkxGZj1YzB5WdX5ez-0cIdTeHl_xV6IIn3zV6_oKk1bH8p7zElArhiUJLrgnAFFy6hW-uJ12fGz6xMk00bhnxxGFN-VEGBVkPusE5ueumcLE-VWrSnY0KGGtSrS4MWXAMSaO36-ORcAyeY_3-nZqTbyYkeT3iQUHnxFi9b7w3XyA_Xg0GYNI9Uw-P4GBlue0ySaVhVz4YSoV9g0XwCDi7O8oFHnH3N_aS8NYTDEPuDpBwZroaotjKNUyY3KfalOETSEgcDy6DtGU0GOOZ51PbI7FBgV3apbYBP4sWW51Xx9i9rcnluYCtjLLHFAIjCMYXgAULeQr2wZExrT2YI7nXqsQXCVQu7aATTyhjqXEqTn4YeHG184WUDsP2asrN6l-dsHYtctgPoEbgsONEsmrIc0i11AiR41MpvMPMMs8uSYZuaeUp9PRNTE-2Qo6kfjnYJKn5JxQBU88IqXMjBXXF_TAW3TFrN84lbVbiePjy3OV6oTCUvX4XTg_8dGWkCIotRQPwWMd3IkPkrf2HXCC__n3-dL6ZiXFSQOikhmWmKnwqAc01VWhWkRdJBRD1vjQxmPzROn1qG7mbfc4OhANaC7VPVhGKGmFSZYDeQFmX4JQ%2526delegated_client_id%253D640853332981.apps.googleusercontent.com%2526hl%253Den";
        // }
    }
    Object.defineProperty(AppComponent.prototype, "subrelease", {
        set: function (sub) {
            this._subrelease.add(sub);
        },
        enumerable: true,
        configurable: true
    });
    AppComponent.prototype.ngOnDestroy = function () {
        this._subrelease.unsubscribe();
    };
    AppComponent.prototype.ngOnInit = function () { };
    AppComponent.prototype.Submit = function () {
        var self = this;
        if (!self.LoginForm) {
            return;
        }
        var form = self.LoginForm.getRawValue();
        if (!form.Username || !form.Password) {
            return;
        }
        // Validate what was provided.
        self.appSvc
            .ValidateCredentials(form.Username, form.Password, "", "", "", window.location.href)
            .subscribe(function (result) {
            // A success result. Clear the error message display (if any)
            self.ErrorMessage = null;
            // Set the properties from the response.
            self.ActionURL = result.ActionURL;
            self.RelayState = self.GoogleRelayState = result.RelayState;
            self.SAMLResponse = result.SAMLResponse;
            // Trigger the Google form
            window.setTimeout(function () {
                var gform = self._GoogleForm.nativeElement;
                gform.submit();
            }, 0);
        }, function (error) {
            var msg = self.errorSvc.RetrieveServiceErrorMessage(error);
            // Display this error in the component field.
            self.ErrorMessage = msg;
            // Clear the login form's password field, set the focus back to that field.
            self.LoginForm.get("Password").setValue(null);
            self.LoginForm.updateValueAndValidity();
            self._PasswordField.nativeElement.focus();
        });
    };
    return AppComponent;
}());
export { AppComponent };
