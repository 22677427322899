import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Subject, Subscription, TeardownLogic } from "rxjs";
import { SessionStorage } from "ngx-webstorage";

import { ConfigService } from "services/config/config.service";

@Injectable({
  providedIn: "root",
})
export class AppService implements OnDestroy {
  private _subrelease: Subscription = new Subscription();
  set subrelease(sub: TeardownLogic) {
    this._subrelease.add(sub);
  }

  @SessionStorage() systemOnline = false;

  SystemOnlineSubject: Subject<TestConnectionPacket> =
    new Subject<TestConnectionPacket>();

  constructor(private configSvc: ConfigService, private http: HttpClient) {
    this.SystemOnlineSubject.next({
      SystemMessage: "",
      Status: this.systemOnline,
      GoogleSSOVersion: "",
    });
  }

  ngOnDestroy(): void {
    this._subrelease.unsubscribe();
  }

  // #region Classlink SSO Login Service Call

  ClasslinkSSOLogin(token: string, screen?: string, winres?: string) {
    return this.http.post<ValidationResult>(
      this.configSvc.hostAddress + "Api/Services/App/ClasslinkSSOLogin",
      {
        Token: token,
        Screen: screen,
        Window: winres,
      }
    );
  }

  // #endregion

  // #region Set System Online Status

  SetSystemOnlineStatus(status: TestConnectionPacket): void {
    this.systemOnline = (status && status.Status) || false;
    this.SystemOnlineSubject.next(status);
  }

  // #endregion

  // #region Test Connection

  TestConnection() {
    return this.http.get<TestConnectionPacket>(
      this.configSvc.hostAddress + `Api/Services/App/TestConnection`
    );
  }

  // #endregion

  // #region Validate Credentials

  ValidateCredentials(
    username: string,
    password: string,
    accesscode: string,
    SAMLRequest: string,
    RelayState: string,
    fullURL: string
  ) {
    return this.http.post<ValidationResult>(
      this.configSvc.hostAddress + `Api/Services/App/ValidateCredentials`,
      {
        Username: username || "",
        Password: password || "",
        AccessCode: accesscode || "",
        SAMLRequest: SAMLRequest || "",
        RelayState: RelayState || "",
        FullURL: fullURL || ''
      }
    );
  }

  // #endregion
}

// #region Custom Classes

// #region Test Connection Packet

export interface TestConnectionPacket {
  GoogleSSOVersion?: string;
  Status: boolean;
  SystemMessage: string;
}

// #endregion

// #region Validation Result Class

export class ValidationResult {
  ActionURL: string;
  RelayState: string;
  SAMLResponse: string;
}

// #endregion

// #endregion
