/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./classlink.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./classlink.component";
import * as i4 from "../../services/application/application.service";
import * as i5 from "@angular/router";
var styles_ClasslinkSSOLoginComponent = [i0.styles];
var RenderType_ClasslinkSSOLoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClasslinkSSOLoginComponent, data: {} });
export { RenderType_ClasslinkSSOLoginComponent as RenderType_ClasslinkSSOLoginComponent };
export function View_ClasslinkSSOLoginComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { _GoogleForm: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "outerwrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "mat-elevation-z3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h2", [["class", "mat-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Welcome to myPolkApps!"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["myPolkApps"])), (_l()(), i1.ɵted(-1, null, [" is a set of collaboration tools, including email (students only), calendar, document sharing services, and more powered by Google Apps. We think you'll find the services to be an exciting way to communicate and collaborate with teachers and students!"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The system is currently processing your login. Please wait... "])), (_l()(), i1.ɵeld(11, 0, null, null, 8, "div", [["class", "hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, [[1, 0], ["googleform", 1]], null, 7, "form", [["id", "googleform"], ["method", "POST"], ["novalidate", ""]], [[1, "action", 4], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(13, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(14, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(16, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 0, "textarea", [["cols", "130"], ["id", "SAMLResponse"], ["name", "SAMLResponse"], ["rows", "15"]], [[8, "value", 0]], null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "textarea", [["cols", "130"], ["id", "GFRelayState"], ["name", "RelayState"], ["rows", "15"]], [[8, "value", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ActionURL; var currVal_1 = i1.ɵnov(_v, 16).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 16).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 16).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 16).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 16).ngClassValid; var currVal_6 = i1.ɵnov(_v, 16).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 16).ngClassPending; _ck(_v, 12, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.SAMLResponse; _ck(_v, 17, 0, currVal_8); var currVal_9 = _co.RelayState; _ck(_v, 19, 0, currVal_9); }); }
export function View_ClasslinkSSOLoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "classlink-login", [], null, null, null, View_ClasslinkSSOLoginComponent_0, RenderType_ClasslinkSSOLoginComponent)), i1.ɵdid(1, 245760, null, 0, i3.ClasslinkSSOLoginComponent, [i4.AppService, i5.ActivatedRoute, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClasslinkSSOLoginComponentNgFactory = i1.ɵccf("classlink-login", i3.ClasslinkSSOLoginComponent, View_ClasslinkSSOLoginComponent_Host_0, {}, {}, []);
export { ClasslinkSSOLoginComponentNgFactory as ClasslinkSSOLoginComponentNgFactory };
