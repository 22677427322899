import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    hostAddress: string;

    constructor() {
        const hostURL = window.location.host.toLowerCase();

        if (hostURL.indexOf('localhost') >= 0 || hostURL === '127.0.0.1') {
            this.hostAddress = 'http://localhost:34107/';
        } else {
            this.hostAddress = 'https://gapps.mypolkschools.net/';
        }
    }
}
