import * as i0 from "@angular/core";
var ConfigService = /** @class */ (function () {
    function ConfigService() {
        var hostURL = window.location.host.toLowerCase();
        if (hostURL.indexOf('localhost') >= 0 || hostURL === '127.0.0.1') {
            this.hostAddress = 'http://localhost:34107/';
        }
        else {
            this.hostAddress = 'https://gapps.mypolkschools.net/';
        }
    }
    ConfigService.ngInjectableDef = i0.defineInjectable({ factory: function ConfigService_Factory() { return new ConfigService(); }, token: ConfigService, providedIn: "root" });
    return ConfigService;
}());
export { ConfigService };
