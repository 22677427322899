<div class="outerwrap">
    <div class="mat-elevation-z3">
        <h2 class="mat-title">Welcome to myPolkApps!</h2>
        <p>
            <strong>myPolkApps</strong> is a set of collaboration tools, including email (students only), calendar, document sharing services, and more powered by Google Apps. We think you'll find the services to be an exciting way to communicate and collaborate with teachers and students!</p>
        <p>
            The system is currently processing your login. Please wait...
        </p>
    </div>
    <div class="hidden">
        <form id="googleform" method="POST" [attr.action]="ActionURL" #googleform>
            <textarea id="SAMLResponse" name="SAMLResponse" cols="130" rows="15" [value]="SAMLResponse"></textarea><br />
            <textarea id="GFRelayState" name="RelayState" cols="130" rows="15" [value]="RelayState"></textarea>
        </form>
    </div>
</div>