import { HttpErrorResponse } from '@angular/common/http';
import * as i0 from "@angular/core";
var ErrorService = /** @class */ (function () {
    function ErrorService() {
    }
    // #region Handle Service Error Service Call
    ErrorService.prototype.HandleServiceError = function (error) {
        var self = this;
        var status = null;
        var msg = null;
        if (error instanceof HttpErrorResponse) {
            status = error.status;
            var body = error && error.error;
            msg = body.ExceptionMessage || body.Message || body || 'An unknown error has occurred.';
        }
        else {
            var errorPacket = error.message || error;
            if (errorPacket != null && errorPacket !== '') {
                errorPacket = JSON.parse(errorPacket);
            }
            msg = (errorPacket != null && errorPacket instanceof Object ?
                errorPacket.ExceptionMessage || errorPacket.Message || error :
                errorPacket || 'An unknown error has occurred.');
        }
        // Display alert
        window.alert(msg);
    };
    // #endregion
    // #region Retrieve Service Error Service Call
    ErrorService.prototype.RetrieveServiceError = function (error) {
        if (error instanceof HttpErrorResponse) {
            var body = error && error.error;
            var msg = body.ExceptionMessage || body.Message || body
                || (typeof (body) === 'string' ? body : 'An unknown error has occurred.');
            return {
                ErrorMessage: msg,
                ErrorObject: error
            };
        }
        else {
            // Try to get a sensible message, then create a fake HttpErrorResponse object
            var errorPacket = error.message || error;
            if (errorPacket != null && errorPacket !== '') {
                errorPacket = JSON.parse(errorPacket);
            }
            var msg = (errorPacket != null && errorPacket instanceof Object ?
                errorPacket.ExceptionMessage || errorPacket.Message || error :
                errorPacket || 'An unknown error has occurred.');
            var errObject = new HttpErrorResponse({
                error: error,
                status: 500,
                statusText: msg
            });
            return {
                ErrorMessage: msg,
                ErrorObject: errObject
            };
        }
    };
    // #endregion
    // #region Retrieve Service Error Message Service Call
    ErrorService.prototype.RetrieveServiceErrorMessage = function (error) {
        var status = null;
        var msg = null;
        if (error instanceof HttpErrorResponse) {
            status = error.status;
            var body = error && error.error;
            msg = body.ExceptionMessage || body.Message || body
                || (typeof (body) === 'string' ? body : 'An unknown error has occurred.');
        }
        else {
            var errorPacket = error.message || error;
            if (errorPacket != null && errorPacket !== '') {
                errorPacket = JSON.parse(errorPacket);
            }
            msg = (errorPacket != null && errorPacket instanceof Object ?
                errorPacket.ExceptionMessage || errorPacket.Message || error :
                errorPacket || 'An unknown error has occurred.');
        }
        if (status === 408) {
            // This is a Request Timeout response, indicating the user's session has expired.
            msg = 'Your current session to the Polk Awards System has timed out and is no longer valid.';
        }
        return msg;
    };
    ErrorService.ngInjectableDef = i0.defineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(); }, token: ErrorService, providedIn: "root" });
    return ErrorService;
}());
export { ErrorService };
// #region Custom Classes
// #region Error Info Class
var ErrorInfo = /** @class */ (function () {
    function ErrorInfo() {
    }
    return ErrorInfo;
}());
export { ErrorInfo };
