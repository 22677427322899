import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    constructor() {

    }

    // #region Handle Service Error Service Call

    HandleServiceError(error: any) {
        const self = this;

        let status: number = null;
        let msg: string = null;

        if (error instanceof HttpErrorResponse) {
            status = error.status;

            const body = error && error.error;
            msg = body.ExceptionMessage || body.Message || body || 'An unknown error has occurred.';
        } else {
            let errorPacket = error.message || error;
            if (errorPacket != null && errorPacket !== '') { errorPacket = JSON.parse(errorPacket); }
            msg = (errorPacket != null && errorPacket instanceof Object ?
                errorPacket.ExceptionMessage || errorPacket.Message || error :
                errorPacket || 'An unknown error has occurred.');
        }

        // Display alert
        window.alert(msg);
    }

    // #endregion

    // #region Retrieve Service Error Service Call

    RetrieveServiceError(error: any) {
        if (error instanceof HttpErrorResponse) {
            const body = error && error.error;
            const msg = body.ExceptionMessage || body.Message || body
                || (typeof(body) === 'string' ? body : 'An unknown error has occurred.');

            return <ErrorInfo>{
                ErrorMessage: msg,
                ErrorObject: error
            };
        } else {
            // Try to get a sensible message, then create a fake HttpErrorResponse object
            let errorPacket = error.message || error;

            if (errorPacket != null && errorPacket !== '') { errorPacket = JSON.parse(errorPacket); }

            const msg = (errorPacket != null && errorPacket instanceof Object ?
                errorPacket.ExceptionMessage || errorPacket.Message || error :
                errorPacket || 'An unknown error has occurred.');

            const errObject = new HttpErrorResponse({
                error: error,
                status: 500, // General internal server error
                statusText: msg
            });

            return <ErrorInfo>{
                ErrorMessage: msg,
                ErrorObject: errObject
            };
        }
    }

    // #endregion

    // #region Retrieve Service Error Message Service Call

    RetrieveServiceErrorMessage(error: any) {
        let status: number = null;
        let msg: string = null;

        if (error instanceof HttpErrorResponse) {
            status = error.status;

            const body = error && error.error;
            msg = body.ExceptionMessage || body.Message || body
                || (typeof(body) === 'string' ? body : 'An unknown error has occurred.');
        } else {
            let errorPacket = error.message || error;
            if (errorPacket != null && errorPacket !== '') { errorPacket = JSON.parse(errorPacket); }
            msg = (errorPacket != null && errorPacket instanceof Object ?
                errorPacket.ExceptionMessage || errorPacket.Message || error :
                errorPacket || 'An unknown error has occurred.');
        }

        if (status === 408) {
            // This is a Request Timeout response, indicating the user's session has expired.
            msg = 'Your current session to the Polk Awards System has timed out and is no longer valid.';
        }

        return msg;
    }

    // #endregion
}

// #region Custom Classes

// #region Error Info Class

export class ErrorInfo {
    ErrorObject: HttpErrorResponse;
    ErrorMessage: string;
}

// #endregion

// #endregion
