import { ElementRef, OnInit, OnDestroy, } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, Validators } from "@angular/forms";
import { Subscription, timer } from "rxjs";
import { map } from "rxjs/operators";
import { AppService } from "services/application/application.service";
import { ErrorService } from "services/error/error.service";
var LoginComponent = /** @class */ (function () {
    function LoginComponent(appSvc, errorSvc, fb, route, router) {
        this.appSvc = appSvc;
        this.errorSvc = errorSvc;
        this.fb = fb;
        this.route = route;
        this.router = router;
        this._subrelease = new Subscription();
    }
    Object.defineProperty(LoginComponent.prototype, "subrelease", {
        set: function (sub) {
            this._subrelease.add(sub);
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.ngOnDestroy = function () {
        if (this._subrelease) {
            this._subrelease.unsubscribe();
        }
    };
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        var self = this;
        self.ActionURL = null;
        self.ErrorMessage = null;
        self.GoogleRelayState = null;
        self.GoogleSAMLRequest = null;
        self.RelayState = null;
        self.SAMLRequest = null;
        self.SAMLResponse = null;
        self.ShowImpersonationBox = false;
        self.LoginForm = self.fb.group({
            Username: [null, Validators.required],
            Password: [null, Validators.required],
            AccessCode: [null],
        });
        // Listen for the parameters (from Google, if present)
        self._subrelease.add(self.route.queryParamMap
            .pipe(map(function (params) {
            return {
                SAMLRequest: params.get("SAMLRequest"),
                RelayState: params.get("RelayState"),
            };
        }))
            .subscribe(function (googleInfo) {
            if (googleInfo != null) {
                self.SAMLRequest = self.GoogleSAMLRequest = !googleInfo.SAMLRequest
                    ? null
                    : decodeURIComponent(googleInfo.SAMLRequest);
                self.RelayState = self.GoogleRelayState = !googleInfo.RelayState
                    ? null
                    : googleInfo.RelayState;
            }
        }));
        // Set up a subscription on Test Connection, to check for the system being available while the user is sitting on the landing page.
        this.subrelease = timer(0, 60000).subscribe(function (_) {
            // Call out to test the connection.
            _this.appSvc.TestConnection().subscribe(function (result) {
                // Update the status.
                _this.appSvc.SetSystemOnlineStatus(result);
            }, function (err) {
                _this.appSvc.SetSystemOnlineStatus({
                    Status: false,
                    SystemMessage: "",
                });
            });
        });
    };
    // #region Show Impersonation Method
    LoginComponent.prototype.ShowImpersonation = function (event) {
        if (!event) {
            return;
        }
        if (event.ctrlKey || event.metaKey || event.tapCount === 2) {
            this.ShowImpersonationBox = !this.ShowImpersonationBox;
        }
    };
    // #endregion
    LoginComponent.prototype.Submit = function () {
        var self = this;
        if (!self.LoginForm) {
            return;
        }
        var form = self.LoginForm.getRawValue();
        if (!form.Username || !form.Password) {
            return;
        }
        // Let's try to help the users out a bit. First, let's trim whitespace of the front and end of the username field.
        var username = form.Username.trim().toLowerCase();
        var toUpdate = false;
        // Now, if there's a domain as part of this, we do some more helpful tricks.
        if (username.indexOf("@") >= 0) {
            // Split off the domain portion and the root portion.
            var root = username.substring(0, username.indexOf("@"));
            var domain = username.substring(username.indexOf("@") + 1);
            // If any of the following common failed domains are found, we can simply replace with the valid one to help out the user.
            // 54 so far - JGK 4/7/20
            var commonBadDomains = [
                "atmypolkschools.net",
                "mpolkschools.net",
                "mupolkschools.net",
                "my polkschools.net",
                "mylolkschools.net",
                "myoolkschools.net",
                "mypilkschools.net",
                "myplkschools.net",
                "myplokschool.net",
                "myplokschoos.net",
                "myplokschools.net",
                "mypoklschools.net",
                "mypokschools.net",
                "mypolk.net",
                "mypolkachools.net",
                "mypolkchools.net",
                "mypolkcountyschools.net",
                "mypolkscgools.net",
                "mypolkschhapools.net",
                "mypolkschhols.net",
                "mypolkschols.net",
                "mypolkscholls.net",
                "mypolk school.net",
                "mypolkschool",
                "mypolkschool.com",
                "mypolkschool.net",
                "mypolkschoold.net",
                "mypolkschoolk.net",
                "mypolkschools",
                "mypolkschools. net",
                "mypolkschools,net",
                "mypolkschools.com",
                "mypolkschools.edu",
                "mypolkschools.nrt",
                "mypolkschools.org",
                "mypolkschools.ney",
                "mypolkschoolsfl.net",
                "mypolkschoolsnet",
                "mypolkschoools.net",
                "mypolkschoos.net",
                "mypolkscools.net",
                "mypolkshcools.com",
                "mypolkshcools.net",
                "mypolkshools",
                "mypolkshools.net",
                "mypolksschools.net",
                "mypollkschools,net",
                "mypollkschools.net",
                "mypollschools.net",
                "mypolschools.net",
                "mypolkstudents.net",
                "mypollkschools.net",
                "myschool.net",
                "myschools.net",
                "my.polkschools.net",
                "polkschool.net",
                "polkschools.net",
                "polkscools.net",
                "ypolkschools.net",
            ];
            if (commonBadDomains.includes(domain)) {
                username = root.trim() + "@mypolkschools.net";
                toUpdate = true;
            }
            else if (root.trim() !== root) {
                username = root.trim() + "@" + domain;
                toUpdate = true;
            }
        }
        // Update the form if we need to.
        if (toUpdate) {
            self.LoginForm.get("Username").setValue(username);
        }
        // Validate what was provided.
        self.appSvc
            .ValidateCredentials(username, form.Password, form.AccessCode, self.SAMLRequest, self.RelayState, window.location.href)
            .subscribe(function (result) {
            // A success result. Clear the error message display (if any)
            self.ErrorMessage = null;
            // Set the properties from the response.
            self.ActionURL = result.ActionURL;
            self.RelayState = self.GoogleRelayState = result.RelayState;
            self.SAMLResponse = result.SAMLResponse;
            // Trigger the Google form
            window.setTimeout(function () {
                var gform = self._GoogleForm.nativeElement;
                gform.submit();
            }, 0);
        }, function (error) {
            var msg = self.errorSvc.RetrieveServiceErrorMessage(error);
            // Display this error in the component field.
            self.ErrorMessage = msg;
            // Clear the login form's password field, set the focus back to that field.
            self.LoginForm.get("Password").setValue(null);
            self.LoginForm.updateValueAndValidity();
            self._PasswordField.nativeElement.focus();
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
