import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appMobileTap]',
})
export class MobileTapDirective {
  @Output() doubleTap = new EventEmitter();

  @HostListener('tap', ['$event'])
  onTap(e) {
    if (e.preventDefault) {
      e.preventDefault();
    }

    if (e.tapCount === 2) {
      this.doubleTap.emit(e);
    }
  }

  constructor() { }
}
